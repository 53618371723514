import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {ApiDocSidebar} from "../../components/sidebar";
import {KeyHeader} from "../../components/typography";
import {ServerLinks} from "../../components/servers";

const fullTitle = "Server Specific Guides";
const shortTitle = "Server Guides";

const Page = () => (
    <Layout htmlTitle={fullTitle}
            pageTitle={
                <>
                    <span className="d-none d-sm-inline">{fullTitle}</span>
                    <span className="d-sm-none">{shortTitle}</span>
                </>}
            sidebarNav={<ApiDocSidebar/>}
            mobileNav={false}
    >
        <KeyHeader className="mt-0">{fullTitle}</KeyHeader>

        <p>
            SQLAPI++ allows to work with a number of SQL
            database servers. It provides common mechanisms to access database, and
            as a general rule they work for any database server. But each server
            has some specific features which a developer has to
            know in order to leverage server's unique features and avoid potential
            errors.
        </p>

        <p>
            In the following topics we tried to collect all {' '}<strong>specific</strong>{' '} information related to
            each server supported by SQLAPI++ library. Full information
            about using SQLAPI++ see in {' '}<Link to="/ApiDoc/">Documentation</Link>.
        </p>

        <p>
            Choose your server to see additional information:
        </p>
        <ul>
            <ServerLinks linkContainer={({children}) => <li>{children}</li>}/>
        </ul>
    </Layout>
);

export default Page;
